<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2023-07-26 14:31:05
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-06-12 15:48:07
 * @Description: 
-->
<template>
	<div>
		<div class="dashboard-wrap" v-if="isSetPassword">
			<div class="dashboard-empty" v-if="categoryList.length === 0">
				<img src="~@/assets/dashboard-empty.svg" alt="" />
				<p>点击右上角设置按钮，设置店铺信息和包间信息后即可开始使用</p>
			</div>
			<div class="dashboard" v-else>
				<div style="height: 40px" v-if="$store.state.user.info.shop && $store.state.user.info.shop.id === 13">
					<a-alert ref="alert1" banner message="本店铺只用于体验使用预订，输入手机号，预订人可接收预订信息。" type="error" class="absolute left-0 top-0 w-full">
						<template slot="icon">
							<a-icon type="info-circle" style="display: flex" />
						</template>
					</a-alert>
				</div>

				<!-- 余额不足提示 -->
				<div style="height: 40px" v-else-if="storeMoney < 300">
					<a-alert ref="alert1" banner type="error" class="absolute left-0 top-0 w-full">
						<template slot="icon">
							<a-icon type="info-circle" style="display: flex; position: relative; top: 4px" />
						</template>
						<template #message>
							<div>
								<span> 您的备用金已经不足3元，您预订下单后将无法自动给顾客发送短信回执和顾客裂变，您可以在线充值继续使用。 </span>
								<a-button @click="getQRCode" ghost type="primary"> 充值 </a-button>
							</div>
						</template>
					</a-alert>

					<a-modal width="680px" :visible.sync="qrCodeVisible" title="店铺备用金充值" @cancel="visibleCancel" @ok="visibleCancel">
						<template>
							<div class="qr-code mx-auto">
								<a-spin :spinning="qrLoading">
									<img :src="qrCode || require('@/assets/qrcode-empty.png')" alt="二维码" class="w-full cursor-pointer" @click="getQRCode(1)" />
								</a-spin>
							</div>
							<div class="pay-tips mx-auto">
								<a-icon type="scan" style="font-size: 30px; margin-right: 8px" />
								<div>请使用微信扫一扫 扫描二维码支付</div>
							</div>
						</template>
					</a-modal>
				</div>

				<a-tabs size="large" v-model="category" @change="getList">
					<a-tab-pane v-for="item in categoryList" :key="item.id" :tab="item.title">
						<!-- 包间搜索 -->
						<div class="options-bar">
							<div class="options-bar-date">
								<a-button icon="left" class="mr-2" @click="operationDate(false)" />
								<a-date-picker :allowClear="false" v-model="orderDate" value-format="YYYY-MM-DD" style="width: calc(100% - 80px)" format="YYYY-MM-DD ddd" />
								<a-button icon="right" class="ml-2" @click="operationDate(true)" />
							</div>

							<div>
								<a-radio-group v-model="roomStatus">
									<a-radio-button :value="0">全部</a-radio-button>
									<a-radio-button :value="1">已订</a-radio-button>
									<a-radio-button :value="2">空闲</a-radio-button>
									<a-radio-button :value="3" @click.stop="onQuickSearch(true)">快搜</a-radio-button>
								</a-radio-group>
							</div>

							<div>
								<a-radio-group v-model="orderType" :default-value="orderType">
									<template v-if="holiday && shopConfig.holiday_twice_meal_time">
										<a-radio-button :value="1" v-if="mealSegment[1].open">{{ mealSegment[1].name }}一轮</a-radio-button>
										<a-radio-button :value="4" v-if="mealSegment[1].open">{{ mealSegment[1].name }}二轮</a-radio-button>
										<a-radio-button :value="2" v-if="mealSegment[2].open">{{ mealSegment[2].name }}一轮</a-radio-button>
										<a-radio-button :value="5" v-if="mealSegment[2].open">{{ mealSegment[2].name }}二轮</a-radio-button>
									</template>
									<template v-else>
										<template v-for="(itemMealSegment, indexMealSegment) in mealSegment">
											<a-radio-button :key="indexMealSegment" :value="indexMealSegment" v-if="itemMealSegment.open">
												{{ itemMealSegment.name }}
											</a-radio-button>
										</template>
									</template>
								</a-radio-group>
							</div>

							<a-space>
								<a-button
									type="primary"
									@click="
										$router.push({
											name: 'BookReview',
											query: {
												orderType: orderType,
												orderDate: orderDate
											}
										})
									">
									预订回访
								</a-button>
								<a-button type="primary" @click="orderRoom()"> 连台预订 </a-button>
								<a-button type="primary" @click="orderPrint()"> 打印接待单 </a-button>
							</a-space>

							<div class="status_box">
								<span class="text">状态：</span>
								<div class="status">
									<span class="circle circle_1"></span>
									<span class="text">空闲</span>
								</div>
								<div class="status">
									<span class="circle circle_2"></span>
									<span class="text">已预订</span>
								</div>
								<div class="status">
									<span class="circle circle_3"></span>
									<span class="text">已确认</span>
								</div>
								<div class="status">
									<span class="circle circle_4"></span>
									<span class="text">已到点</span>
								</div>
								<div class="status">
									<span class="circle circle_5"></span>
									<span class="text">客已到/已占座</span>
								</div>
							</div>
						</div>

						<!-- 包间内容 -->
						<div class="room-list">
							<a-row :gutter="[25, 25]" type="flex" v-if="list.length > 0">
								<!-- 每个包间 -->
								<a-col :xl="{span: 6}" :lg="{span: 8}" :md="{span: 12}" :xs="{span: 24}" v-for="item in list" :key="item.id">
									<!--空闲-->
									<div v-if="item.order === 0" class="room" @click.stop="orderRoom(item)">
										<div class="room-main">
											<div class="room-status">
												<span class="flex items-center"> 空闲 </span>
												<span>
													{{ `可坐${item.number}人` }}
												</span>
											</div>
											<div class="room-name" v-if="item.order === 0">
												{{ item.title }}
											</div>
										</div>
										<div class="room-footer">
											<a-row class="w-full h-full" type="flex" align="middle">
												<a-col span="12" class="line-right h-full" v-if="isShowTakeUp">
													<button @click.stop="takeUp(item)" class="w-full h-full text-center">占座</button>
												</a-col>
												<a-col :span="isShowTakeUp ? 12 : 24" class="line-right h-full">
													<button class="w-full h-full text-center">立即预订</button>
												</a-col>
											</a-row>
										</div>
									</div>

									<!--已经预订 、 已经占座、确认-->
									<template v-else-if="item.order === 1">
										<!-- 客已到 -->
										<template v-if="item.order_info.client_arrive === 1">
											<div class="room room-active is-seat">
												<div class="room-main">
													<div class="room-status">
														<div class="flex items-center w-4/5">
															<span style="width: 45px">客已到</span>
															<template v-if="(shopConfig.disable_cancel_change == 1 && item.order_info.mini_user_openid == '') || shopConfig.disable_cancel_change == 0">
																<img
																	:style="{
																		width: '20px',
																		height: '20px'
																	}"
																	:src="changeRoomSVG"
																	v-if="item.isEven != 1"
																	class="ml-2 cursor-pointer inline-block"
																	@click="showChangeRoom(item)" />
															</template>
														</div>
														<span>
															{{ `${item.order_info.number}${item.order_info.unit ? item.order_info.unit : '人'}` }}
														</span>
													</div>
													<div class="subscribe-info">
														<div class="flex text-dark text-center">
															<div
																:class="[
																	'w-1/2 text-lg font-bold flex',
																	{
																		flexcolumn: item.order_info.name.length > 2
																	}
																]">
																<div class="flex justify-center flex-wrap" style="line-height: 1">
																	<span>
																		{{ `${item.order_info.name}${item.order_info.sex === 1 ? '先生' : item.order_info.sex === 2 ? '女士' : ''}` }}
																	</span>
																	<span v-if="item.order_info.tag" :class="['customer-tag', getTagClassName(item.order_info.tag)]"> {{ item.order_info.tag }}{{ item.order_info.tag != '散客' ? '顾客' : '' }} </span>
																</div>
															</div>
															<div class="w-1/2 room-name-box">
																<div class="text-lg font-bold">
																	{{ item.title }}
																</div>
																<img src="../../../src/assets/even_icon_pc.png" v-if="item.isEven" class="room-name-icon" />
															</div>
														</div>
														<div class="flex text-dark text-center mt-2">
															<div class="w-1/2 text-base">
																{{ item.order_info.phone ? `${item.order_info.phone.slice(0, 3)}****${item.order_info.phone.slice(-4)}` : '无' }}
															</div>
															<div class="w-1/2 text-base">
																{{ item.order_info.order_time }}
															</div>
														</div>
													</div>
												</div>
												<div class="room-footer">
													<a-row style="width: 100%">
														<a-col span="12" class="line-right">
															<a class="font-active block text-center" @click="settlement(item)"> 结算清台 </a>
														</a-col>
														<a-col span="12">
															<board-info :info="item.order_info"></board-info>
														</a-col>
													</a-row>
												</div>
											</div>
										</template>
										<!--已经占座-->
										<template v-if="item.order_info.hold_seat === 1">
											<div class="room room-active is-seat">
												<div class="room-main">
													<div class="room-status">
														<span class="flex items-center"> 已占座 </span>
														<span>
															{{ `${item.number}人` }}
														</span>
													</div>
													<div class="subscribe-info">
														<div class="flex text-dark text-center">
															<div class="w-1/2 text-lg font-bold flex">
																<span> 已占座 </span>
															</div>
															<div class="w-1/2 room-name-box">
																<div class="text-lg font-bold">
																	{{ item.title }}
																</div>
																<img src="../../../src/assets/even_icon_pc.png" v-if="item.isEven" class="room-name-icon" />
															</div>
														</div>
													</div>
												</div>
												<div class="room-footer">
													<a-row style="width: 100%">
														<a-col span="12" class="line-right">
															<a class="font-active block text-center" @click="settlement(item)"> 结算清台 </a>
														</a-col>
														<a-col span="12">
															<board-info :info="item.order_info"></board-info>
														</a-col>
													</a-row>
												</div>
											</div>
										</template>
										<!--已到点-->
										<template v-else-if="item.order_info.isTimeout && item.order_info.client_arrive !== 1">
											<div class="room room-active is-timeout">
												<div class="room-main">
													<div class="room-status">
														<div class="flex items-center w-4/5">
															已到点
															<template v-if="Date.parse(item.order_info.order_date + ' ' + item.order_info.order_time) > todayzero">
																<a-icon
																	type="edit"
																	:style="{
																		fontSize: '20px'
																	}"
																	class="ml-2 cursor-pointer"
																	@click="editOrderRoom(item)" />
																<template v-if="(shopConfig.disable_cancel_change == 1 && item.order_info.mini_user_openid == '') || shopConfig.disable_cancel_change == 0">
																	<img
																		:style="{
																			width: '20px',
																			height: '20px'
																		}"
																		:src="changeRoomSVG"
																		v-if="item.isEven != 1"
																		class="ml-2 cursor-pointer inline-block"
																		@click="showChangeRoom(item)" />
																</template>
															</template>
															<span class="room-remark" :title="item.order_info.remark.text">
																{{ item.order_info.remark.text }}
															</span>
														</div>
														<span>
															{{ `${item.order_info.number}${item.order_info.unit ? item.order_info.unit : '人'}` }}
														</span>
													</div>
													<div class="subscribe-info">
														<div class="flex text-dark text-center">
															<div
																:class="[
																	'w-1/2 text-lg font-bold flex',
																	{
																		flexcolumn: item.order_info.name.length > 2
																	}
																]">
																<div class="flex justify-center flex-wrap" style="line-height: 1">
																	<span>
																		{{ `${item.order_info.name}${item.order_info.sex === 1 ? '先生' : item.order_info.sex === 2 ? '女士' : ''}` }}
																	</span>
																	<span v-if="item.order_info.tag" :class="['customer-tag', getTagClassName(item.order_info.tag)]"> {{ item.order_info.tag }}{{ item.order_info.tag != '散客' ? '顾客' : '' }} </span>
																</div>
															</div>
															<div class="w-1/2 room-name-box">
																<div class="text-lg font-bold">
																	{{ item.title }}
																</div>
																<img src="../../../src/assets/even_icon_pc.png" v-if="item.isEven" class="room-name-icon" />
															</div>
														</div>
														<div class="flex text-dark text-center mt-2">
															<div class="w-1/2 text-base">
																{{ item.order_info.phone ? `${item.order_info.phone.slice(0, 3)}****${item.order_info.phone.slice(-4)}` : '无' }}
															</div>
															<div class="w-1/2 text-base">
																{{ item.order_info.order_time }}
															</div>
														</div>
													</div>
												</div>
												<div class="room-footer">
													<a-row style="width: 100%">
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="clickClientArrive(item)"> 客已到 </a>
														</a-col>
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="showCancelOrder(item)"> 取消预订 </a>
														</a-col>
														<a-col span="8">
															<board-info :info="item.order_info"></board-info>
														</a-col>
													</a-row>
												</div>
											</div>
										</template>
										<!--已确认-->
										<template v-else-if="(item.order_info.confirm === 1 || item.order_info.confirm === 4) && item.order_info.client_arrive !== 1">
											<div class="room room-active is-submit">
												<div class="room-main">
													<div class="room-status">
														<div class="flex items-center w-4/5">
															<template v-if="Date.parse(item.order_info.order_date + ' ' + item.order_info.order_time) / 1000 > today">
																已确认
																<a-icon
																	type="edit"
																	class="ml-2 cursor-pointer"
																	:style="{
																		fontSize: '20px'
																	}"
																	@click="editOrderRoom(item)" />
																<template v-if="(shopConfig.disable_cancel_change == 1 && item.order_info.mini_user_openid == '') || shopConfig.disable_cancel_change == 0">
																	<img
																		:style="{
																			width: '20px',
																			height: '20px'
																		}"
																		:src="changeRoomSVG"
																		v-if="item.isEven != 1"
																		class="ml-2 cursor-pointer inline-block"
																		@click="showChangeRoom(item)" />
																</template>
															</template>
															<span class="room-remark" :title="item.order_info.remark.text">
																{{ item.order_info.remark.text }}
															</span>
														</div>
														<span>
															{{ `${item.order_info.number}${item.order_info.unit ? item.order_info.unit : '人'}` }}
														</span>
													</div>
													<div class="subscribe-info">
														<div class="flex text-dark text-center">
															<div
																:class="[
																	'w-1/2 text-lg font-bold flex',
																	{
																		flexcolumn: item.order_info.name.length > 2
																	}
																]">
																<div class="flex justify-center flex-wrap" style="line-height: 1">
																	<span>
																		{{ `${item.order_info.name}${item.order_info.sex === 1 ? '先生' : item.order_info.sex === 2 ? '女士' : ''}` }}
																	</span>
																	<span v-if="item.order_info.tag" :class="['customer-tag', getTagClassName(item.order_info.tag)]"> {{ item.order_info.tag }}{{ item.order_info.tag != '散客' ? '顾客' : '' }} </span>
																</div>
															</div>
															<div class="w-1/2 room-name-box">
																<div class="text-lg font-bold">
																	{{ item.title }}
																</div>
																<img src="../../../src/assets/even_icon_pc.png" v-if="item.isEven" class="room-name-icon" />
															</div>
														</div>
														<div class="flex text-dark text-center mt-2">
															<div class="w-1/2 text-base">
																{{ item.order_info.phone ? `${item.order_info.phone.slice(0, 3)}****${item.order_info.phone.slice(-4)}` : '无' }}
															</div>
															<div class="w-1/2 text-base">
																{{ item.order_info.order_time }}
															</div>
														</div>
													</div>
												</div>
												<div class="room-footer">
													<a-row style="width: 100%">
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="clickClientArrive(item)"> 客已到 </a>
														</a-col>
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="showCancelOrder(item)"> 取消预订 </a>
														</a-col>
														<a-col span="8">
															<board-info :info="item.order_info"></board-info>
														</a-col>
													</a-row>
												</div>
											</div>
										</template>
										<!--已预订-->
										<template v-else-if="item.order_info.client_arrive !== 1">
											<div class="room room-active is-booking">
												<div class="room-main">
													<div class="room-status">
														<div class="flex items-center w-4/5">
															<span style="width: 45px">已预订</span>
															<template v-if="Date.parse(item.order_info.order_date + ' ' + item.order_info.order_time) / 1000 > today">
																<a-icon
																	type="edit"
																	class="ml-2 cursor-pointer"
																	:style="{
																		fontSize: '20px'
																	}"
																	@click="editOrderRoom(item)" />

																<template v-if="item.order_info.with_a_map_order">
																	<img style="margin-left: 6px; cursor: pointer; width: 20px; height: 20px" @click="showAMapTip($event, 1)" src="https://luyopay-djbj.oss-accelerate-overseas.aliyuncs.com/mini/amap/pc_amap_icon2.png" v-if="item.order_info.with_a_map_order.reschedule == 1" />
																	<img style="margin-left: 6px; cursor: pointer; width: 20px; height: 20px" @click="showAMapTip($event, 2, item.order_info.with_a_map_order)" src="https://luyopay-djbj.oss-accelerate-overseas.aliyuncs.com/mini/amap/pc_amap_icon1.png" v-if="item.order_info.with_a_map_order.latestEta" />
																</template>

																<template v-if="(shopConfig.disable_cancel_change == 1 && item.order_info.mini_user_openid == '') || shopConfig.disable_cancel_change == 0">
																	<img
																		:style="{
																			width: '20px',
																			height: '20px'
																		}"
																		:src="changeRoomSVG"
																		v-if="item.isEven != 1"
																		class="ml-2 cursor-pointer inline-block"
																		@click="showChangeRoom(item)" />
																</template>
															</template>
															<span class="room-remark" :title="item.order_info.remark.text">
																{{ item.order_info.remark.text }}
															</span>
														</div>
														<span>
															{{ `${item.order_info.number}${item.order_info.unit ? item.order_info.unit : '人'}` }}
														</span>
													</div>
													<div class="subscribe-info">
														<div class="flex text-dark text-center">
															<div
																:class="[
																	'w-1/2 text-lg font-bold flex',
																	{
																		flexcolumn: item.order_info.name.length > 2
																	}
																]">
																<div class="flex justify-center flex-wrap" style="line-height: 1">
																	<span>
																		{{ `${item.order_info.name}${item.order_info.sex === 1 ? '先生' : item.order_info.sex === 2 ? '女士' : ''}` }}
																	</span>
																	<span v-if="item.order_info.tag" :class="['customer-tag', getTagClassName(item.order_info.tag)]"> {{ item.order_info.tag }}{{ item.order_info.tag != '散客' ? '顾客' : '' }} </span>
																</div>
															</div>
															<div class="w-1/2 room-name-box">
																<div class="text-lg font-bold">
																	{{ item.title }}
																</div>
																<img src="../../../src/assets/even_icon_pc.png" v-if="item.isEven" class="room-name-icon" />
															</div>
														</div>
														<div class="flex text-dark text-center mt-2">
															<div class="w-1/2 text-base">
																{{ item.order_info.phone ? `${item.order_info.phone.slice(0, 3)}****${item.order_info.phone.slice(-4)}` : '无' }}
															</div>
															<div class="w-1/2 text-base">
																{{ item.order_info.order_time }}
															</div>
														</div>
													</div>
												</div>
												<div class="room-footer">
													<a-row style="width: 100%">
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="clickClientArrive(item)"> 客已到 </a>
														</a-col>
														<a-col span="8" class="line-right">
															<a class="font-active block text-center" @click="showCancelOrder(item)"> 取消预订 </a>
														</a-col>
														<a-col span="8">
															<board-info :info="item.order_info"></board-info>
														</a-col>
													</a-row>
												</div>
											</div>
										</template>
									</template>
								</a-col>
							</a-row>
							<div class="dashboard-empty room-list-empty" v-else>
								<img src="~@/assets/dashboard-empty.svg" alt="" />
								<p>暂无可用包间</p>
							</div>
						</div>
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>
		<div class="first-login" v-else>
			<div class="first-login-form">
				<h3 class="text-center font-bold text-xl mt-6 mb-6">设置操作密码</h3>
				<a-form-model ref="password" :model="passwordForm" :label-col="{span: 6}" :wrapper-col="{span: 18}">
					<a-form-model-item
						label="后台设置密码"
						prop="password"
						:rules="{
							required: true,
							message: '请输入密码',
							pattern: /^[a-zA-Z0-9`!@#$%^&*()_+.]+$/
						}">
						<template #help>
							<p class="first-login-help">此密码用于后台右上角进入“设置”，请牢记该密码，以便后期使用。</p>
						</template>
						<a-input v-model.trim="passwordForm.password" type="password" placeholder="请输入密码" />
					</a-form-model-item>
					<div class="flex justify-center items-center mt-9">
						<a-button type="primary" size="large" @click="savePassword"> 保存 </a-button>
					</div>
				</a-form-model>
			</div>
		</div>
		<a-modal
			dialog-class="modal-content"
			title="结算清台"
			okText="结算清台"
			width="600px"
			:mask-closable="false"
			:visible.sync="settlementVisible"
			@cancel="
				settlementForm = {}
				$refs.settlement.clearValidate()
				settlementVisible = false
			"
			@ok="settlementConfirm">
			<a-form-model ref="settlement" :model="settlementForm" :label-col="{span: 4}" :wrapper-col="{span: 20}">
				<template v-if="settlementForm.order_info && settlementForm.order_info.with_corp_user">
					<a-form-model-item label="包间名">
						{{ settlementForm.title }}
					</a-form-model-item>
					<a-form-model-item label="销售">
						<template v-if="settlementForm.order_info.with_corp_user && settlementForm.order_info.with_corp_user.name">
							{{ settlementForm.order_info.with_corp_user.name }}
						</template>
						<ww-open-data v-else type="userName" :openid="settlementForm.order_info.with_corp_user && settlementForm.order_info.with_corp_user.userid" />
					</a-form-model-item>
				</template>
				<a-form-model-item
					prop="money"
					label="销售额"
					:rules="{
						required: true,
						message: '请输入消费总额',
						min: 0
					}">
					<a-input v-model="settlementForm.money" placeholder="请输入消费总额" :min="0" type="number" addon-after="元" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!-- 提交预定modal-form -->
		<a-modal width="680px" okText="提交预订" :title="orderTitle" :mask-closable="false" :visible.sync="orderVisible" :dialog-style="{top: '20px'}" @cancel="onCancelOrderRoom" @ok="orderRoomConfirm(1)">
			<template slot="footer" v-if="isNoteServe">
				<template v-if="(currentOrderForm.mini_user_openid == '' && shopConfig.disable_cancel_change == 1) || !currentOrderForm.id || shopConfig.disable_cancel_change == 0">
					<a-button @click="orderRoomConfirm(0)" type="secondary"> 提交预订</a-button>
					<a-button @click="orderRoomConfirm(1)" type="primary">提交预订并发送短信</a-button>
				</template>
				<template v-else>
					{{ null }}
				</template>
			</template>
			<a-form-model ref="order" :model="currentOrderForm" :label-col="{span: 6}" :wrapper-col="{span: 16}">
				<a-form-model-item label="日期">
					<a-row>
						<a-col span="6">{{ orderDate }}</a-col>
						<a-col :span="currentOrderForm.title ? 6 : 8" class="text-center"> {{ currentOrderForm.title ? '类型' : '就餐类型' }}：{{ orderTypeName }} </a-col>
						<a-col span="12" class="text-center" v-if="currentOrderForm.title"> 包间名：{{ currentOrderForm.title }} </a-col>
					</a-row>
				</a-form-model-item>

				<a-form-model-item
					v-if="!currentOrderForm.title"
					:rules="{
						required: true,
						message: '请选择连台包间'
					}"
					prop="room_id"
					label="选择包间">
					<a-tree-select
						v-model="currentOrderForm.room_id"
						tree-data-simple-mode
						style="width: 100%"
						:tree-data="roomTreeData"
						tree-checkable
						:show-checked-strategy="SHOW_PARENT"
						placeholder="请选择连台包间"
						:load-data="onLoadData"
						:dropdown-style="{
							maxHeight: '200px',
							overflow: 'auto'
						}" />
				</a-form-model-item>

				<template v-if="orderTitle === '为顾客订包间'">
					<!--有Corpid-->
					<a-form-model-item v-if="hasCorpId" prop="corp_user_id" label="接待人">
						<a-select v-model="currentOrderForm.corp_user_id" allowClear class="search-bar-input" placeholder="请选择接待人">
							<a-select-option v-for="item in employeeList" :key="item.id" :value="item.id">
								<template v-if="item.name">{{ item.name }}</template>
								<ww-open-data v-else type="userName" :openid="item.userid" />
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<!--没有有Corpid-->
					<a-form-model-item v-else prop="mini_user_openid" label="接待人">
						<a-select v-model="currentOrderForm.mini_user_openid" placeholder="请选择接待人">
							<a-select-option :value="item.openid" v-for="item in employeeList" :key="item.id">
								{{ item.nickname || item.mini_user.nickname || '' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</template>
				<a-form-model-item
					:rules="{
						max: 30,
						required: true,
						message: '请输入预订人（不超过30个字符）'
					}"
					prop="name"
					label="预订人">
					<a-input v-model="currentOrderForm.name" placeholder="贵姓" />
				</a-form-model-item>
				<a-form-model-item prop="sex" label="性别">
					<a-radio-group v-model="currentOrderForm.sex">
						<a-radio :value="1">先生</a-radio>
						<a-radio :value="2">女士</a-radio>
						<a-radio :value="0">无</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<!--1.5改动：手机号不是必填项，但是必须展示星号。使用自定义校验函数。-->
				<a-form-model-item
					prop="phone"
					label="手机号"
					:rules="{
						required: true,
						validator: (rule, value, callback) => callback()
					}">
					<a-input v-model="currentOrderForm.phone" placeholder="手机号" type="tel" :max-length="11" />
				</a-form-model-item>
				<a-form-model-item :rules="{required: true, message: '请输入预计到店人数'}" prop="number" label="预计到店人数">
					<a-input v-model.number="currentOrderForm.number" placeholder="预计到店人数" type="number" min="1">
						<template #addonAfter>
							<a-select v-model="currentOrderForm.unit" style="width: 60px">
								<a-select-option value="人">人</a-select-option>
								<a-select-option value="桌">桌</a-select-option>
							</a-select>
						</template>
					</a-input>
				</a-form-model-item>
				<!-- #2666隐藏编辑订单中的日期选择 -->
				<!-- <a-form-model-item
					v-if="orderTitle === '修改包间预订信息'"
					:rules="{ required: true, message: '请选择预计到店时间' }"
					prop="order_time"
					label="预计到店日期">
					<a-date-picker
						v-model="currentOrderForm.order_date"
						style="width: 100%"
						placeholder="预计到店日期"
						value-format="YYYY-MM-DD" />
				</a-form-model-item> -->
				<a-form-model-item :rules="{required: true, message: '请选择预计到店时间'}" prop="order_time" label="预计到店时间">
					<div class="time-list">
						<div
							:class="{
								'time-item': true,
								'time-item-active': currentOrderForm.order_time === item
							}"
							v-for="item in timeList[orderType]"
							:key="item"
							@click="$set(currentOrderForm, 'order_time', item)">
							{{ item }}
						</div>
					</div>
				</a-form-model-item>
				<a-form-model-item prop="type" label="邀请函样式">
					<a-radio-group v-model="currentOrderForm.type" :options="orderTypes" @change="orderTypeChange"></a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="备注" prop="remark.text" :rules="{max: 100, message: '备注最多只能100个字'}">
					<a-textarea v-model="currentOrderForm.remark.text" showCount :max-length="100" />
				</a-form-model-item>
			</a-form-model>
			<div class="tips" v-if="(currentOrderForm.mini_user_openid == '' && disableCancelChange == 1) || !currentOrderForm.id">
				<span v-if="!isNoteServe">提示：预订成功后会自动发送短信回执和邀请函给顾客。</span>
				<span v-else>提示：选择发送短信后，会自动发送短信到顾客手机，内容为预订信息和就餐邀请函。</span>
			</div>
		</a-modal>
		<!-- S Modal 转台modal -->
		<a-modal title="转台" v-model="changeRoomVisible" width="680px" :mask-closable="false" :footer="null">
			<a-tabs size="large" v-model="changeRoomCate" @change="getChangeRoomList()">
				<a-tab-pane v-for="item in categoryList" :key="item.id" :tab="item.title">
					<div class="options-bar">
						<div class="options-bar-date">
							<a-button icon="left" class="mr-2" @click="changeRoomOptDate(false)" />
							<a-date-picker :allowClear="false" v-model="changeRoomDate" value-format="YYYY-MM-DD" style="width: calc(100% - 80px)" format="YYYY-MM-DD ddd" :popupStyle="{zIndex: '999999'}" />
							<a-button icon="right" class="ml-2" @click="changeRoomOptDate(true)" />
						</div>
						<div>
							<a-radio-group v-model="changeRoomOrderType" :default-value="changeRoomOrderType">
								<template v-if="holiday && shopConfig.holiday_twice_meal_time">
									<a-radio-button :value="1" v-if="mealSegment[1].open">{{ mealSegment[1].name }}一轮</a-radio-button>
									<a-radio-button :value="4" v-if="mealSegment[1].open">{{ mealSegment[1].name }}二轮</a-radio-button>
									<a-radio-button :value="2" v-if="mealSegment[2].open">{{ mealSegment[2].name }}一轮</a-radio-button>
									<a-radio-button :value="5" v-if="mealSegment[2].open">{{ mealSegment[2].name }}二轮</a-radio-button>
								</template>
								<template v-else>
									<template v-for="(itemMealSegment, indexMealSegment) in mealSegment">
										<a-radio-button :key="indexMealSegment" :value="indexMealSegment" v-if="itemMealSegment.open">
											{{ itemMealSegment.name }}
										</a-radio-button>
									</template>
								</template>
							</a-radio-group>

							<!-- <a-radio-group v-model="changeRoomOrderType" :default-value="changeRoomOrderType">
								<template v-if="holiday && shopConfig.holiday_twice_meal_time">
									<a-radio-button :value="1"> 午餐一轮</a-radio-button>
									<a-radio-button :value="4"> 午餐二轮</a-radio-button>
									<a-radio-button :value="2"> 晚餐一轮</a-radio-button>
									<a-radio-button :value="5"> 晚餐二轮</a-radio-button>
								</template>
								<template v-else>
									<template v-for="(itemRadio, indexRadio) in mealSegment">
										<a-radio-button :key="indexRadio" :value="indexRadio" v-if="item.open">
											{{ item.name }}
										</a-radio-button>
									</template>
								</template>
							</a-radio-group> -->
						</div>
					</div>
					<a-table :columns="columns" :data-source="changeRoomList" :loading="changeRoomLoading" :pagination="false" :scroll="{y: 455}" rowKey="id">
						<template #action="record">
							<a-button v-if="!record.isSelf && record.order == '0'" ghost type="primary" @click="onSelectOrderTime(record, '0')"> 确定 </a-button>
							<a-button v-if="!record.isSelf && record.order == '1' && record.order_info.order_date === recordBefore.order_info.order_date && record.order_info.order_type === recordBefore.order_info.order_type" ghost type="primary" :class="'primary_exchange'" @click="onSelectOrderTime(record, '1')"> 调换 </a-button>
						</template>
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
		<!-- E Modal 转台modal -->
		<a-modal v-model="showOrderTime" title="预计到店时间" :footer="null">
			<div class="time-list">
				<div
					:class="{
						'time-item': true,
						'time-item-active': currentOrderForm.order_time === item
					}"
					v-for="item in timeList[changeRoomOrderType]"
					:key="item"
					@click="handleOrderTime(item)">
					{{ item }}
				</div>
			</div>
		</a-modal>
		<!-- 取消预订modal -->
		<a-modal v-model="cancelOrderVisible" title="取消预订">
			<template #footer>
				<a-button key="back" @click="closeCancelOrder">关闭</a-button>
				<a-button key="submit" type="primary" :loading="cancelLoading" @click="cancelOrder(0)"> 取消预订 </a-button>
				<a-button key="submitAndSend" type="primary" :loading="cancelLoading" @click="cancelOrder(1)"> 取消预订并发送短信 </a-button>
			</template>
			<p>确定要取消该包间的预订吗？</p>
		</a-modal>
		<!-- 余额不足 -->
		<a-modal v-model:visible="showMoneyTip" title="提示">
			<template #footer>
				<a-button key="back" @click="showMoneyTip = false">关闭</a-button>
				<a-button
					key="submit"
					type="primary"
					@click="
						showMoneyTip = false
						getQRCode()
					"
					>立即充值</a-button
				>
			</template>
			<p>您的店铺备用金已耗尽，无法再发送短信，请充值后再使用。</p>
		</a-modal>
		<!-- 高德提示 -->
		<a-modal :title="null" :footer="null" :mask="false" v-model:visible="amapModalVisible" centered="centered">
			<p>{{ amapModalText }}</p>
		</a-modal>
		<!-- 快搜 -->
		<a-modal v-model="isShowQuickSearch" title="快搜" :mask-closable="false" :footer="null" @cancel="onQuickSearch(false)">
			<a-space v-if="quickSearchKeywordList.length > 0" class="name-wrapper">
				<a-button v-for="item in quickSearchKeywordList" :key="item" @click="onChangeSearchName(item)">{{ item }}</a-button>
			</a-space>
			<!--<a-space size="large" wrap v-else>
				<a-button type="primary" danger loading>Loading...</a-button>
			</a-space>-->
			<div class="search-wrapper">
				<div class="search-title">订单快搜</div>
				<div class="search-tips">输入手机号后四位或点击预订人可快速定位订单</div>
				<div class="search-box">
					<a-input type="number" :auto-focus="true" v-model:value="quickSearchPhoneValue" :controls="false" @change="onChangeSearchPhone" />
					<div class="search-box-list">
						<template v-for="(item, index) in quickSearchPhoneList">
							<div class="search-item" :key="index">
								<div v-if="item.value">{{ item.value }}</div>
								<div class="search-item_focus" v-if="item.focus">|</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import {exchangeRoom, changeRoom, sendSms, cancelOrderRoom, editOrderRoom, getRoomList, getRoomListV2, orderRoom, setPassword, settlement, takeUpSeat, clientArrive, getIsHoliday, orderPrint} from '@/api/dashboard'
import {getEmployeeList} from '@/api/employee'
import {getIsBindingList, getWorkMakeMiniQrcode} from '@/api/mobileWorkbench'
import {getRoomCategoryList} from '@/api/roomSetting'

import _ from 'lodash'
import {TreeSelect} from 'ant-design-vue'
import moment from 'moment'
import changeRoomSVG from '@/assets/change-room.svg'
import store from '@/store'
import lgLoading from '@/utils/loading'
import {readDicFn} from '@/utils/utils'
import {phoneReg} from '@/utils/validate'
import BoardInfo from '../../components/boardInfo'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
// 格式化时间数字，不足两位补0
const formatNumber = n => {
	n = n.toString()
	return n[1] ? n : `0${n}`
}

export default {
	name: 'Dashboard',
	mixins: [lgLoading],
	components: {
		BoardInfo
	},
	data() {
		return {
			SHOW_PARENT,
			qrLoading: false,
			qrCodeVisible: false,
			qrCode: '',
			isSetPassword: true,
			passwordForm: {
				password: ''
			},
			// 结算清台
			settlementVisible: false,
			settlementForm: {},
			// 预订
			orderVisible: false,
			orderTitle: '为顾客订包间',
			currentOrderForm: {
				remark: {
					image: '',
					text: ''
				}
			},
			list: [],
			categoryList: [],
			category: undefined,
			categoryForTabs: undefined,
			// 房间状态
			roomStatus: 0,
			today: Date.parse(new Date()) / 1000, // 预订时间
			todayzero: new Date(new Date().toLocaleDateString()).getTime(),
			orderType: null, // 预订类型默认为null, 等待created触发后修改为自定义对应餐段，触发watch，触发调用getList
			orderTypeName: '', // 预订类型中文名
			orderDate: moment().format('YYYY-MM-DD'),
			phoneReg,
			showOrderTime: false,
			timer: null,
			timeList: {},
			storeMoney: 0,
			miniAmount: '',
			mealSegment: [
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				},
				{
					name: ''
				}
			], //自定义餐段
			changeRoomSVG,
			changeRoomVisible: false,
			changeRoomLoading: false,
			changeRoomOrderId: '',
			changeRoomRelation: '',
			changeRoomCate: undefined,
			changeRoomDate: moment().format('YYYY-MM-DD'),
			changeRoomOrderType: null,
			changeRoomList: [],
			emptyRoomList: [],
			columns: [
				{
					title: '包间分类',
					customRender: (text, record) => this.categoryList.find(item => item.id === record.category_id).title
				},
				{
					title: '包间名称',
					dataIndex: 'title'
				},
				{
					title: '容客人数',
					dataIndex: 'number'
				},
				{
					title: '转台',
					key: 'action',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			employeeList: [],
			changeRoomSMS: 0,
			info: {}, // 预订信息
			// 邀请函样式
			orderTypes: [
				{
					label: '黑金商务',
					value: 0
				},
				{
					label: '艳红喜庆',
					value: 2
				},
				{
					label: '蓝灰沉稳',
					value: 3
				},
				{
					label: '粉红欢快',
					value: 1
				},
				{
					label: '金色辉煌',
					value: 4
				},
				{
					label: '绿意盎然',
					value: 5
				}
			], //预定类型字典
			isNoteServe: false, //店铺是否配置发送短信功能
			roomTreeData: [], //选择包间
			cancelOrderVisible: false, //取消预订modal
			cancelLoading: false,
			showMoneyTip: false, //余额不足提示
			disableCancelChange: 0,
			shopConfig: {},
			holiday: false, //当前选择的日期是否为节假日
			amapModalVisible: false,
			amapModalText: '',
			isShowQuickSearch: false, // 显示快搜弹窗
			quickSearchKeywordList: [], // 快搜关键字列表
			quickSearchPhoneList: [
				{
					value: '',
					focus: false
				},
				{
					value: '',
					focus: false
				},
				{
					value: '',
					focus: false
				},
				{
					value: '',
					focus: false
				}
			],
			quickSearchPhoneValue: ''
		}
	},
	async created() {
		await this.getStoreInfo() // 获取店铺信息
	},
	mounted() {
		// const userInfo = storage.get(USER_INFO);
		this.isNoteServe = true // 默认全部发送短信
		this.isHoliday(this.orderDate)
	},
	beforeDestroy() {
		this.stopLoop()
	},
	destroyed() {
		this.stopLoop()
	},
	watch: {
		/* 监听查询条件 */
		roomStatus() {
			if (this.roomStatus !== 3) {
				this.quickSearchPhoneValue = ''
				this.quickSearchPhoneList = [
					{
						value: '',
						focus: false
					},
					{
						value: '',
						focus: false
					},
					{
						value: '',
						focus: false
					},
					{
						value: '',
						focus: false
					}
				]
				this.quickSearchKeywordList = []
				this.getList()
			}
		},
		orderType() {
			this.getList()
		},
		orderDate() {
			this.orderType = 1
			this.isHoliday(this.orderDate)
			this.getList()
		},
		changeRoomDate() {
			this.changeRoomOrderType = 1
			this.isHoliday(this.changeRoomDate)
			this.getChangeRoomList()
		},
		changeRoomOrderType() {
			this.getChangeRoomList()
		}
	},
	computed: {
		isShowTakeUp() {
			// return [1, 2].includes(this.orderType) && this.orderDate === this.getToday()
			return this.orderDate === this.getToday()
		},
		hasCorpId() {
			return this.$store.state.user.info.shop && this.$store.state.user.info.shop.corpid
		}
	},
	methods: {
		showAMapTip(event, type, data) {
			this.amapModalVisible = true
			this.amapModalText = ['', '顾客已通过高德改期到店', `顾客正导航到店中预计到达时间 ${data?.latestEta || ''}`][type]
		},
		visibleCancel() {
			this.qrCodeVisible = false
			this.qrLoading = false
		},
		getTagClassName(tagName) {
			const index = {散客: 1, 普通: 2, 重要: 3, 核心: 4}[tagName]
			return `tag-${index}`
		},
		/**
		 * @description: 点击重置请求二维码图片，打开二维码
		 * @return {*}
		 * @param {*} refresh
		 */
		getQRCode(refresh = 0) {
			this.qrLoading = true
			// 拼装入参
			const params = {
				page: 'work_pages/pages/recharge/recharge',
				params: `shop_id=${this.$store.state.user.info.shop.id}`,
				width: 200
			}
			this.qrCodeVisible = true
			// 通过公共二维码获取接口获取二维码图片链接
			getWorkMakeMiniQrcode(params).then(res => {
				if (res.code === 0) {
					this.qrCode = res.data
					this.qrLoading = false
					if (refresh === 1) {
						this.$message.success('刷新成功！')
					}
				}
			})
		},
		isAm() {
			const date = new Date()
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			const day = date.getDate()
			const pmTime = new Date(`${[year, month, day].map(formatNumber).join('-')} 14:30`).getTime()
			const nowTime = new Date().getTime()
			return nowTime > pmTime
		},
		getToday() {
			return moment(new Date()).format('YYYY-MM-DD')
		},
		setMiniAmount(templateType = '') {
			if (templateType / 1 === 3) {
				this.miniAmount = 50
			} else if (templateType / 1 === 2) {
				this.miniAmount = 30
			} else if (templateType / 1 === 1) {
				this.miniAmount = 10
			}
		},
		/**
		 * @description: 获取门店登录信息
		 * @return {*}
		 */
		getStoreInfo() {
			this.addLoading()
			// 获取本地是否已设置过设置密码
			let isInitPassword = this.$store.state.user.info.shop?.password ?? false
			this.$store
				.dispatch('user/GetInfo')
				.then(() => {
					let {info = {}} = this.$store.state.user
					if (isInitPassword) {
						info.shop.password = true
					}
					if (!isInitPassword && info.shop.password) {
						isInitPassword = true
					}
					// 配置赋值
					this.isSetPassword = isInitPassword
					this.changeRoomSMS = info?.shop?.to_sms_config?.sms_config?.change_room_sms
					this.disableCancelChange = info?.shop?.to_sms_config?.sms_config?.disable_cancel_change ?? 0
					this.mealSegment = info?.shop?.to_sms_config?.sms_config?.meal_segment ?? []
					this.shopConfig = info?.shop?.to_sms_config?.sms_config
					this.storeMoney = info?.money
					this.orderType = this.handleCurrentMealSegment(null, this.mealSegment)
					this.changeRoomOrderType = this.orderType
					this.setMiniAmount(info?.shop?.to_sms_config?.sms_config?.template_type)
					this.handleMealTimeList()

					this.getCategory() // 获取分类列表
					this.getEmployees() // 获取员工列表
				})
				.finally(() => {
					this.subLoading()
				})
		},
		/**
		 * @description: 一分钟轮询一次接口， 刷新请求列表
		 * @return {*}
		 */
		async startLoop() {
			this.stopLoop()

			let params = {
				category_id: this.category,
				order_date: this.orderDate,
				order_type: this.orderType,
				status: this.roomStatus
			}
			// 全部分类剔除分类ID
			if (params.category_id === 0) delete params.category_id
			// 快搜功能
			if (this.quickSearchPhoneValue && this.quickSearchPhoneValue.length === 4) {
				params.keyword_phone = this.quickSearchPhoneValue
				params.status = 1
			}
			// V2接口获取包间列表
			let {code, data} = await getRoomListV2(params)

			if (code === 0) {
				this.list = data.map(item => {
					if (item.order === 1) {
						const nowTime = new Date().getTime()
						const orderTime = new Date(`${item.order_info.order_date} ${item.order_info.order_time}`).getTime()
						this.$set(item.order_info, 'isTimeout', nowTime > orderTime)
					}
					if (item.order_info && item.order_info.relation && !item.isEven) {
						item.isEven = 0
						data.map(info => {
							if (info.order_info?.relation === item.order_info?.relation) {
								item.isEven = 1
							}
						})
					}

					return item
				})

				this.timer = setTimeout(this.startLoop, 3 * 60 * 1000)
			}
		},
		/**
		 * @description: 停止轮询
		 * @return {*}
		 */
		stopLoop: function () {
			if (this.timer) {
				clearInterval(this.timer)
				this.timer = null
			}
		},
		/**
		 * @description: 获取包间tabs数据(如：大厅\散台)，动态插入全部类目
		 * @return {*}
		 */
		async getCategory() {
			let {code, data} = await getRoomCategoryList()

			if (code === 0) {
				this.categoryList = data

				// 根据设置是否显示全部
				let isShowAllRooms = this.shopConfig?.staple_show_all_rooms ?? false
				if (isShowAllRooms) {
					this.categoryList.splice(0, 0, {
						id: 0,
						shop_id: 0,
						sort: 0,
						title: '全部',
						type_id: 0
					})
				}

				// 赋值
				this.category = this.categoryList[0].id
				this.changeRoomCate = this.categoryList[0].id
			}
		},
		/**
		 * @description: 切换包间列表
		 * @return {*}
		 */
		async getChangeRoomList() {
			this.changeRoomLoading = true
			this.changeRoomList = []

			let params = {
				category_id: this.changeRoomCate,
				order_date: this.changeRoomDate,
				order_type: this.changeRoomOrderType,
				status: 0
			}
			// 全部分类剔除分类ID
			if (params.category_id === 0) delete params.category_id
			// V2接口获取包间列表
			let {code, data} = await getRoomListV2(params)

			if (code === 0) {
				this.changeRoomList = data.map(item => {
					item.isSelf = !!((item?.order_info?.relation && this.changeRoomRelation && item?.order_info?.relation === this.changeRoomRelation) || item?.order_info?.id === this.changeRoomOrderId || item?.order_info?.client_arrive === 1)
					return item
				})
			}

			this.changeRoomLoading = false
		},
		/**
		 * @description: 获取包间列表
		 * @return {*}
		 */
		async getList() {
			this.addLoading()
			// 餐段二轮名称
			this.orderTypeName = [4, 5].includes(this.orderType) ? `${this.mealSegment[this.orderType - 2].name}二轮` : this.mealSegment[this.orderType].name

			let params = {
				category_id: this.category,
				order_date: this.orderDate,
				order_type: this.orderType,
				status: this.roomStatus
			}
			// 全部分类剔除分类ID
			if (params.category_id === 0) delete params.category_id
			// 快搜功能(包括关键字和手机号后四位)
			if (this.quickSearchPhoneValue) {
				params.keyword_phone = this.quickSearchPhoneValue
				params.status = 1
			}
			// V2接口获取包间列表
			let {code, data} = await getRoomListV2(params)

			if (code === 0) {
				// 判断是否已到点
				this.list = data.map(item => {
					if (item.order === 1) {
						const nowTime = new Date().getTime()
						const orderTime = new Date(`${item.order_info.order_date} ${item.order_info.order_time}`).getTime()
						this.$set(item.order_info, 'isTimeout', nowTime > orderTime)
					}
					return item
				})
			}

			await this.startLoop()
			await this.subLoading()
		},
		/**
		 * @description: 根据餐段配置来筛选当前餐段
		 * @return {*}
		 * @param {*} list 餐段配置列表
		 */
		handleCurrentMealSegment(targetTime, targetList = []) {
			// 格式化时间戳
			const formatGetTime = (formatTime, isFullDate = false) => {
				return new Date(isFullDate ? formatTime : `${[year, month, day].map(formatNumber).join('/')} ${formatTime}`).getTime()
			}
			// 获取当前时间
			const date = this.getBeijingTime()
			const year = date.getUTCFullYear()
			const month = date.getUTCMonth() + 1
			const day = date.getUTCDate()
			const currentTime = targetTime ? formatGetTime(targetTime) : formatGetTime(date, true) //格式化传入时间戳
			// 自定义餐段开始/结束时间和餐段类型
			let startTime = 0,
				endTime = 0,
				mealType = 1
			// 存在自定义餐段
			if (targetList && targetList.length > 0) {
				// 如果当前时间不在全部餐段时间内，则默认为第一个开启的餐段
				mealType = targetList.findIndex(item => item.open)
				targetList.forEach((item, index) => {
					if (item.open) {
						// 上一个餐段结束时间到下一个餐段结束时间，中间的区间均属于下一餐段范围
						endTime = formatGetTime(item.time_ranger[1])
						if (startTime < currentTime && currentTime <= endTime) {
							mealType = index
						}
					}

					startTime = formatGetTime(item.time_ranger[1])
				})
			}

			return mealType
		},
		/**
		 * @description: 获取当前北京时间
		 * @return {*}
		 */
		getBeijingTime() {
			// 获得当前运行环境时间
			let d = new Date()
			let currentDate = new Date()
			let tmpHours = currentDate.getHours()
			let time_zone = -d.getTimezoneOffset() / 60 //算的时区
			// 计算范围
			if (time_zone < 0) {
				time_zone = Math.abs(time_zone) + 8
				currentDate.setHours(tmpHours + time_zone)
			} else {
				time_zone -= 8
				currentDate.setHours(tmpHours - time_zone)
			}

			return currentDate
		},
		/**
		 * @description: 处理餐段时间区间
		 * @return {*}
		 */
		handleMealTimeList() {
			const filterTimeRange = timeRange => {
				let timeList = []
				let startTime = new Date(`${this.orderDate} ${timeRange[0]}`)
				let endTime = new Date(`${this.orderDate} ${timeRange[1]}`)
				let currentTime = startTime.getTime()
				while (currentTime <= endTime.getTime()) {
					let tempTime = new Date(currentTime)
					timeList.push(tempTime.toTimeString().substring(0, 5)) // 获取时间的HH:mm格式
					currentTime += 30 * 60 * 1000 // 增加30分钟
				}
				return timeList
			}
			// 当前日期
			let tempTimeList = {}
			this.mealSegment.forEach((item, index) => {
				tempTimeList[index] = filterTimeRange(item.time_ranger)
			})
			// 增加二轮时间
			tempTimeList[4] = tempTimeList[1] //午餐二轮
			tempTimeList[5] = tempTimeList[2] //晚餐二轮

			this.timeList = tempTimeList
		},
		/**
		 * @description: 保存密码
		 * @return {*}
		 */
		savePassword() {
			this.$refs.password
				.validate()
				.then(() => {
					this.$confirm({
						content: '确定保存吗？',
						title: '保存密码',
						onOk: () => {
							setPassword(this.passwordForm).then(res => {
								if (res.code === 0) {
									this.$message.success('设置成功！')
									// 构造相同的用户信息obj再存到storage
									const initInfo = {
										auth_type: 'work_admin',
										shop: _.cloneDeep(res.data),
										user: {
											phone: res.data.phone
										}
									}
									store.commit('user/SET_INFO', initInfo)
									this.isSetPassword = this.$store.state.user.info.shop.password
								}
							})
						}
					})
				})
				.catch(err => err)
		},

		// 预定包间-预定类型修改
		orderTypeChange(e) {
			// this.currentOrderForm.remark = readDicFn(e.target.value, this.orderTypes)
			// if (this.currentOrderForm.remark === '通用')
			//   this.currentOrderForm.remark = ''
		},
		/**
		 * @description: 关闭预订下单/修改订单弹窗
		 * @return {*}
		 */
		onCancelOrderRoom() {
			this.$refs.order.clearValidate()
			this.orderVisible = false
			this.$nextTick(() => {
				setTimeout(() => {
					this.currentOrderForm = {
						remark: {
							image: '',
							text: ''
						}
					}
					this.roomTreeData = []
				}, 500)
			})
		},
		/**
		 * 预订包间-空闲
		 * */
		orderRoom(item) {
			if (item) {
				if (item.order === 1) return
				this.currentOrderForm = {
					room_id: item.id,
					title: item.title,
					sex: 1,
					type: this.$store.state.user?.info?.shop?.default_order_theme ?? 0,
					remark: {
						image: '',
						text: ''
					},
					unit: '人',
					order_time: this.timeList[this.orderType][0]
				}
			} else {
				this.currentOrderForm = {
					room_id: [],
					sex: 1,
					type: this.$store.state.user?.info?.shop?.default_order_theme ?? 0,
					remark: {
						image: '',
						text: ''
					},
					unit: '人'
				}
				this.roomTreeData = []
				this.roomTreeData = this.categoryList.map(item => ({
					id: item.id + '_f',
					value: item.id,
					pId: 0,
					title: item.title,
					checkable: false
				}))
			}
			this.orderTitle = '为顾客订包间'
			this.orderVisible = true
		},
		/**
		 * @description: 打印接待单
		 * @return {*}
		 */
		orderPrint() {
			this.addLoading()
			orderPrint({
				date: this.orderDate,
				type: this.orderType
			}).then(res => {
				this.subLoading()
				if (res.code == '0') {
					this.$warning({
						title: '提示',
						content: '已提交打印任务，请关注小票机打印接待单。'
					})
				}
			})
		},
		// 异步获取包间列表
		onLoadData(treeNode) {
			return new Promise(resolve => {
				const {value: categoryId, id: PId} = treeNode.dataRef
				getRoomList({
					category_id: categoryId,
					order_date: this.orderDate,
					status: 2,
					order_type: this.orderType
				}).then(res => {
					const list = (res.data.list || []).map(item => ({
						id: item.id,
						value: item.id,
						pId: PId,
						title: item.title,
						isLeaf: true
					}))
					this.roomTreeData = this.roomTreeData.concat(list)
					resolve()
				})
			})
		},
		/**
		 * @description: 编辑预订订单
		 * @return {*}
		 * @param {*} item
		 */
		editOrderRoom(item) {
			this.currentOrderForm = {
				..._.cloneDeep(item.order_info),
				title: item.title
			}

			this.orderTitle = '修改包间预订信息'
			this.orderVisible = true
		},

		/**
		 * @description: 预定下单
		 * @return {*}
		 */
		orderRoomConfirm(sendSms) {
			// 备用金不足发短信
			if (sendSms === 1 && this.storeMoney < 20 && !this.currentOrderForm.id) {
				this.showMoneyTip = true
				this.orderVisible = false
				return
			}
			this.$refs.order.validate().then(() => {
				if (this.orderTitle === '为顾客订包间') {
					let data = {
						...this.currentOrderForm,
						order_date: this.orderDate,
						order_type: this.orderType,
						send_sms: sendSms
					}
					// 剔除不能传空值的key
					if (data.hasOwnProperty('dining_name') && !data.dining_name) {
						delete data.dining_name
					}
					// 剔除空值参数
					// for (const key in params) {
					// 	if (!params[key]) delete params[key]
					// }
					this.$message.loading({content: '预订中', key: 'order'})
					orderRoom(data).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '预订成功！',
								key: 'order'
							})
							this.orderVisible = false
							this.getList()
							this.roomTreeData = []
						}
					})
				} else {
					if (this.currentOrderForm.relation) {
						this.$confirm({
							title: '提示',
							content: '该订单为连台订单，是否需要一起操作？',
							okText: '是',
							cancelText: '否',
							onOk: () => {
								this.handleEditOrder({
									...this.currentOrderForm,
									edit_relation: 1
								})
							},
							onCancel: () => {
								this.handleEditOrder({
									...this.currentOrderForm,
									edit_relation: 0
								})
							}
						})
					} else {
						this.handleEditOrder({
							...this.currentOrderForm,
							send_sms: sendSms
						})
					}
				}
			})
		},
		/**
		 * @description: 修改预订订单
		 * @return {*}
		 * @param {*} data // 参数JSON对象
		 */
		handleEditOrder(data) {
			this.$message.loading({content: '修改中', key: 'order'})
			// 剔除不能传空值的key
			if (data.hasOwnProperty('dining_name') && !data.dining_name) {
				delete data.dining_name
			}
			editOrderRoom(data).then(res => {
				if (res.code === 0) {
					this.$message.success({
						content: '修改成功！',
						key: 'order'
					})
					this.orderVisible = false
					this.getList()
					this.roomTreeData = []
				}
			})
		},
		closeCancelOrder() {
			this.cancelOrderVisible = false
		},
		showCancelOrder(item) {
			this.cancelOrderVisible = true
			this.currentOrder = item
		},
		/**
		 * @description: 取消预订订单
		 * @return {*}
		 * @param {*} send // 是否需要发送短信0不需要 1需要
		 */
		cancelOrder(send = 0) {
			if (this.currentOrder && this.currentOrder.order_info.relation) {
				this.$confirm({
					title: '提示',
					content: '该订单为连台订单，是否需要一起操作？',
					okText: '是',
					cancelText: '否',
					onOk: () => {
						this.handleCancelOrder(send, 1)
					},
					onCancel: () => {
						this.handleCancelOrder(send, 0)
					}
				})
			} else {
				this.handleCancelOrder(send, 0)
			}
		},
		/**
		 * @description: 处理取消预订订单
		 * @return {*}
		 * @param {*} send // 是否需要发送短信0不需要 1需要
		 * @param {*} cancel_relation // 是否需要连台取消0不需要 1需要
		 */
		handleCancelOrder(send, cancel_relation) {
			this.cancelLoading = true
			cancelOrderRoom({
				id: this.currentOrder.order_info.id,
				send,
				cancel_relation
			}).then(res => {
				this.cancelOrderVisible = false
				this.cancelLoading = false
				if (res.code === 0) {
					this.$message.success({
						content: '操作成功！',
						key: 'order'
					})
					this.getList()
				}
			})
		},
		/**
		 * 清台
		 * */
		settlement(item) {
			if (this.$store.state.user.info.shop && !this.$store.state.user.info.shop.commission > 0) {
				this.$confirm({
					title: '提示',
					content: '你确定清台该包间吗？',
					onOk: () => {
						this.$message.loading({
							content: '清台中',
							key: 'order'
						})
						settlement({
							id: item.order_info.id,
							money: 0
						}).then(res => {
							if (res.code === 0) {
								this.$message.success({
									content: '清台成功！',
									key: 'order'
								})
								this.settlementVisible = false
								this.getList()
							}
						})
					}
				})
				return
			}
			this.settlementForm = _.cloneDeep(item)
			this.settlementVisible = true
		},
		settlementConfirm() {
			this.$refs.settlement
				.validate()
				.then(() => {
					this.$message.loading({content: '清台中', key: 'order'})
					settlement({
						id: this.settlementForm.order_info.id,
						money: this.settlementForm.money
					}).then(res => {
						if (res.code === 0) {
							this.$message.success({
								content: '清台成功！',
								key: 'order'
							})
							this.settlementVisible = false
							this.getList()
						}
					})
				})
				.catch(err => err)
		},
		/**
		 * 占座
		 * */
		takeUp(item) {
			takeUpSeat({
				room_id: item.id,
				order_type: this.orderType
			}).then(res => {
				if (res.code === 0) {
					this.getList()
				}
			})
		},
		/**
		 * @description: 获取空闲包间
		 * @return {*}
		 */
		// async getEmptyRoom() {
		// 	this.changeRoomLoading = true

		// 	let params = {
		// 		category_id: this.category,
		// 		order_date: this.orderDate,
		// 		order_type: this.orderType,
		// 		status: 0
		// 	}
		// 	// 全部分类剔除分类ID
		// 	if (params.category_id === 0) delete params.category_id
		// 	// V2接口获取包间列表
		// 	let {code, data} = await getRoomListV2(params)

		// 	if (code === 0) {
		// 		this.emptyRoomList =
		// 			data.length &&
		// 			data.map(item => {
		// 				item.isSelf = !!((item?.order_info?.relation && this.changeRoomRelation && item?.order_info?.relation === this.changeRoomRelation) || item?.order_info?.id === this.changeRoomOrderId || item?.order_info?.client_arrive === 1)
		// 				return item
		// 			})
		// 	}

		// 	this.changeRoomLoading = false
		// },
		/**
		 * @description: 转台弹窗
		 * @return {*}
		 * @param {*} item 需要转台订单
		 */
		showChangeRoom(item) {
			this.recordBefore = item // 发起转台订单
			this.changeRoomVisible = true
			this.changeRoomOrderId = item.order_info.id || ''
			this.changeRoomRelation = item.order_info.relation || ''

			this.getChangeRoomList()
		},
		/**
		 * @description: 选择预计到店时间
		 * @return {*}
		 * @param {*} item
		 */
		onSelectOrderTime(item, type) {
			this.record = item // 被转台订单
			if (type == '1') this.changeRoom()
			else this.showOrderTime = true
		},
		/**
		 * @description: 处理预计到店时间
		 * @return {*}
		 * @param {*} item
		 */
		handleOrderTime(item) {
			this.changeOrderTime = item
			this.changeRoom()
		},
		/**
		 * @description: 转台/调换
		 * @return {*}
		 */
		async changeRoom() {
			let {id, order, order_info} = this.record
			let tempResult
			// 0不发SMS 1发SMS
			if (this.changeRoomSMS == '1') {
				this.$message.loading({content: '转台中', key: 'changeRoom'})
				if (order == '1') {
					// 调换
					tempResult = await exchangeRoom({
						id: this.changeRoomOrderId,
						exchange_id: order_info.id
					})
				} else {
					// 转台
					tempResult = await changeRoom({
						id: this.changeRoomOrderId,
						room_id: id,
						order_date: this.changeRoomDate,
						order_time: this.changeOrderTime,
						order_type: this.changeRoomOrderType
					})
				}
				if (tempResult.code !== '1') {
					this.getList()
					this.changeRoomVisible = false
					this.showOrderTime = false
					this.$confirm({
						title: '提示',
						content: `${order == '1' ? '调换' : '转台'}成功，是否发送短信给预订人？`,
						cancelText: '否',
						okText: '是',
						closable: true,
						onOk: async () => {
							this.$message.loading({
								content: '短信发送中',
								key: 'changeRoom'
							})
							// 转台
							if (order == '0') {
								let {code} = await sendSms({
									order_id: this.changeRoomOrderId
								})
								if (code == '0') {
									this.$message.success({
										content: '发送成功！',
										key: 'changeRoom'
									})
								}
							}
							// 调换
							if (order == '1' && order_info?.id) {
								Promise.all([
									sendSms({
										order_id: this.changeRoomOrderId
									}),
									sendSms({order_id: order_info?.id})
								]).then(res => {
									if (res[0].code == '0' && res[1].code == '0') {
										this.$message.success({
											content: '短信发送成功！',
											key: 'changeRoom'
										})
									}
								})
							}
						}
					})
				}
			} else {
				this.$confirm({
					title: '提示',
					content: order == '1' ? '该餐位已有预订，是否调换转台？' : '确认转台到该餐位吗？',
					onOk: async () => {
						this.$message.loading({
							content: '转台中',
							key: 'changeRoom'
						})
						if (order == '1') {
							// 调换
							tempResult = await exchangeRoom({
								id: this.changeRoomOrderId,
								exchange_id: order_info.id
							})
						} else {
							// 转台
							tempResult = await changeRoom({
								id: this.changeRoomOrderId,
								room_id: id,
								order_date: this.changeRoomDate,
								order_time: this.changeOrderTime,
								order_type: this.changeRoomOrderType
							})
						}
						if (tempResult.code !== '1') {
							this.$message.success({
								content: '转台成功！',
								key: 'changeRoom'
							})
							this.getList()
							this.changeRoomVisible = false
							this.showOrderTime = false
						} else {
							this.$message.error({
								content: '转台失败！请重试！',
								key: 'changeRoom'
							})
						}
					}
				})
			}
		},
		/**
		 * @description 日期加减
		 * @param isPlus{boolean} 是否增加
		 * */
		changeRoomOptDate(isPlus) {
			const date = moment(this.changeRoomDate)
			let result
			if (isPlus) {
				result = date.add(1, 'd').format('YYYY-MM-DD')
			} else {
				result = date.subtract(1, 'd').format('YYYY-MM-DD')
			}
			this.changeRoomDate = result
		},
		/**
		 * @description 日期加减
		 * @param isPlus{boolean} 是否增加
		 * */
		operationDate(isPlus) {
			const date = moment(this.orderDate)
			let result
			if (isPlus) {
				result = date.add(1, 'd').format('YYYY-MM-DD')
			} else {
				result = date.subtract(1, 'd').format('YYYY-MM-DD')
			}

			this.orderDate = result
		},
		isHoliday(date) {
			getIsHoliday({date}).then(res => {
				this.holiday = res.data.is_holiday
			})
		},
		/**
		 * 获取员工或者移动工作台绑定人员
		 * */
		getEmployees() {
			if (this.hasCorpId) {
				getEmployeeList().then(res => {
					if (res.code === 0) {
						this.employeeList = res.data
					}
				})
			} else {
				getIsBindingList().then(res => {
					if (res.code === 0) {
						this.employeeList = res.data
					}
				})
			}
		},
		/**
		 * @description: 操作客已到状态
		 * @return {*}
		 * @param {*} item 当前项
		 */
		clickClientArrive(item) {
			this.$confirm({
				title: '提示',
				content: '确认该顾客已到店了吗？',
				onOk: () => {
					if (item.isEven) {
						this.$confirm({
							title: '提示',
							content: '该订单为连台订单，是否需要一起操作？',
							okText: '是',
							cancelText: '否',
							onOk: () => {
								this.handleClientArrive(item.order_info.id, 1)
							},
							onCancel: () => {
								this.handleClientArrive(item.order_info.id, 0)
							}
						})
					} else {
						this.handleClientArrive(item.order_info.id, 0)
					}
				}
			})
		},
		/**
		 * @description: 操作可以到提示
		 * @return {*}
		 * @param {*} id // 订单ID
		 * @param {*} relation // 是否需要连台取消0不需要 1需要
		 */
		handleClientArrive(id, arrive_relation) {
			this.$message.loading({content: '确认中', key: 'order'})
			clientArrive({
				id,
				arrive_relation
			}).then(res => {
				if (res.code === 0) {
					this.$message.success({
						content: '确认成功！',
						key: 'order'
					})
					this.getList()
				}
			})
		},
		closeMoneyTip() {
			this.showMoneyTip = false
		},
		/**
		 * @description: 快搜
		 * @return {*}
		 */
		async onQuickSearch(type) {
			this.isShowQuickSearch = type
			// 取消快搜后重置状态为全部
			// 重置快搜输入内容
			if (!type) {
				this.roomStatus = 0
				this.quickSearchKeywordList = []
				this.quickSearchPhoneValue = ''
				this.quickSearchPhoneList.map(item => {
					item.focus = false
					item.value = ''
				})
				return
			}

			// 获取订单
			let params = {
				category_id: this.category,
				order_date: this.orderDate,
				order_type: this.orderType,
				status: 0
			}
			// 全部分类剔除分类ID
			if (params.category_id === 0) delete params.category_id
			// V2接口获取包间列表
			let {code, data} = await getRoomListV2(params)
			let tempKeywordList = []

			if (code === 0) {
				data.forEach(item => {
					if (item.order_info && item.order_info.name) {
						tempKeywordList.push(item.order_info.name)
					}
				})
				this.quickSearchKeywordList = [...new Set(JSON.parse(JSON.stringify(tempKeywordList)))]
				this.quickSearchPhoneList[0].focus = true // 触发自动焦点
			}
		},
		/**
		 * @description: 监听手机号输入
		 * @return {*}
		 */
		onChangeSearchPhone(ev) {
			this.quickSearchPhoneList.forEach((item, index) => {
				if (ev.data && index === this.quickSearchPhoneValue.length - 1) {
					item.value = ev.data
					item.focus = false
				}
				if (!ev.data && index === this.quickSearchPhoneValue.length) {
					item.value = ev.data
					item.focus = true
				} else {
					item.focus = false
				}
			})
			// 激活下一个焦点
			let tmpCurrentIndex = this.quickSearchPhoneList.findIndex(item => !item.value)
			if (tmpCurrentIndex !== -1) {
				this.quickSearchPhoneList[tmpCurrentIndex].focus = true
			}
			// 手机号后四位匹配搜索
			if (this.quickSearchPhoneValue.length === 4) {
				this.isShowQuickSearch = false
				this.getList()
			}
		},
		/**
		 * @description: 根据预订人名字快速搜索
		 * @return {*}
		 * @param {*} name
		 */
		onChangeSearchName(name) {
			this.quickSearchPhoneValue = name
			this.isShowQuickSearch = false
			this.getList()
		}
	}
}
</script>

<style lang="less" scoped>
// ::v-deep .ant-modal-body {
//   padding: 0 24px 24px !important;
// }
input[type='number'] {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	min-height: 100px;
	opacity: 0;
	z-index: 10;
	color: #fff;
	overflow: hidden;
	width: 200%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	height: auto;
	-webkit-appearance: none;
}

.ant-tabs-tab-active {
	font-weight: 700;
}

.primary_exchange {
	color: #30c383 !important;
	border-color: #30c383 !important;
	text-shadow: none;
}

.font-bold {
	font-weight: 500 !important;
}

.dashboard-wrap {
	background-color: #f0f2f5;
	padding: 24px;
}

.first-login {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: calc(100vh - 66px);
	background-image: url(~@/assets/first-login.svg);
}

.first-login-form {
	width: 680px;
	height: 279px;
	background: #ffffff;
	box-shadow: 0 10px 29px 0 #ffdbdb;
	border-radius: 8px;
	box-sizing: border-box;
	padding: 0 69px;
	overflow: hidden;
}

.first-login-help {
	font-size: 13px;
	color: #e63e30;
	line-height: 23px;
	/*margin-top: 7px;*/
}

.dashboard {
	width: 100%;
	min-height: 100%;
	background-color: white;
	box-sizing: border-box;
	position: relative;
	border-radius: 4px;
	padding: 25px 22px;
}

.options-bar {
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	margin-top: 9px;
}

.options-bar > div {
	margin-right: 25px;
	margin-bottom: 25px;
}

.options-bar > .options-bar-date {
	width: 20rem;
}

.room-list {
	min-height: calc(100vh - 292px);
}

.room {
	width: 100%;
	height: 174px;
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
	background: #f9f9f9;
	border: 1px solid #e8e8e8;
	cursor: pointer;
}

.room:hover {
	box-shadow: 0 2px 8px 1px #f2f2f2;
}

.room-main {
	width: 100%;
	height: 132px;
	border-bottom: 1px solid #e8e8e8;
	box-sizing: border-box;
	// padding: 12px;
}

.room-footer {
	width: 100%;
	background-color: white;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	height: 40px;
	border-radius: 0 0 2px 2px;
}

.room-status {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 12px;
	height: 40px;
	font-size: 14px;
	line-height: 40px;
	color: #666;
}

.room-name-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.room-name-icon {
	width: 20px;
	height: 20px;
	margin-left: 5px;
	display: block;
}

.room-name {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - 60px);
}

.subscribe-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100% - 70px);
	margin-top: 10px;
}

.subscribe-info > .flex > .flex {
	justify-content: center;
	align-items: center;
	word-break: keep-all;
}

.subscribe-info .flexcolumn {
	flex-direction: column;
	align-items: center;
}

.subscribe-info .flexcolumn .vip_box {
	margin-top: 4px;
}

.subscribe-info .vip_box {
	display: flex;
	align-items: center;
	margin-left: 4px;
}

.subscribe-info .vip_box .lf {
	height: 16px;
	background: #d6ab6b;
	border-radius: 3px 0 0 3px;
	font-weight: 600;
	font-size: 10px;
	padding: 0 3px;
	line-height: 16px;
}

.subscribe-info .vip_box .rg {
	height: 16px;
	background: #6c593d;
	font-weight: Bold;
	font-size: 12px;
	line-height: 16px;
	padding: 0 3px;
	border-radius: 0 3px 3px 0;
}

.room-active {
	cursor: auto;
}

.room-active .room-main {
	width: 100%;
	height: 132px;
	background-color: #fff;
	border-radius: 4px 4px 0 0;
	color: #333;
}

.room-active .room-status {
	color: #fff;
}

.is-booking {
	border: 1px solid #ffbeb7;
}

.is-booking a {
	color: #e63e30;
}

.is-booking:hover {
	box-shadow: 0 2px 8px 1px #ffdcdc;
}

.is-booking .room-status {
	background-image: linear-gradient(90deg, #e63e30 0%, #fd786d 100%);
}

.is-booking .main_vip {
	background-image: url(~@/assets/vip_red.png), linear-gradient(90deg, #e63e30 0%, #fd786d 100%) !important;
	background-position: right, top;
	background-repeat: no-repeat, no-repeat;
	background-size: auto 103%, cover;
}

/*已到点*/

.is-timeout {
	border: 1px solid #ffceb6;
}

.is-timeout a {
	color: #fc723f;
}

.is-timeout:hover {
	box-shadow: 0 2px 8px 1px #ffece0;
}

// .is-timeout > .room-main {
//   background-image: linear-gradient(270deg, #feab73 0%, #fc723f 100%);
// }

.is-timeout .room-status {
	background-image: linear-gradient(270deg, #feab73 0%, #fc723f 100%);
}

.is-timeout .main_vip {
	background-image: url(~@/assets/vip_og.png), linear-gradient(270deg, #feab73 0%, #fc723f 100%) !important;
	background-position: right, top;
	background-repeat: no-repeat, no-repeat;
	background-size: auto 103%, cover;
}

/*已确认*/

.is-submit {
	border: 1px solid #b5eaee;
}

.is-submit a {
	color: #09afe1;
}

.is-submit:hover {
	box-shadow: 0 2px 8px 1px #dbfcff;
}

.is-submit .room-status {
	background-image: linear-gradient(269deg, #1edbea 2%, #08aee1 96%);
}

.is-submit .main_vip {
	background-image: url(~@/assets/vip_blue.png), linear-gradient(269deg, #1edbea 2%, #08aee1 96%) !important;
	background-position: right, top;
	background-repeat: no-repeat, no-repeat;
	background-size: auto 103%, cover;
}

/*已占座 、客已到*/

.is-seat {
	border: 1px solid #c5ebda;
}

.is-seat a {
	color: #32c485;
}

.is-seat:hover {
	box-shadow: 0 2px 8px 1px #eafff6;
}

// .is-seat > .room-main {
//   background-image: linear-gradient(90deg, #2dc181 0%, #63e2ab 98%);
// }

.is-seat .room-status {
	background-image: linear-gradient(90deg, #2dc181 0%, #63e2ab 98%);
}

.is-seat .main_vip {
	background-image: url(~@/assets/vip_green.png), linear-gradient(90deg, #2dc181 0%, #63e2ab 98%) !important;
	background-position: right, top;
	background-repeat: no-repeat, no-repeat;
	background-size: auto 103%, cover;
}

.dashboard-empty {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	line-height: 24px;
	color: #999999;
	height: calc(100vh - 114px);
}

.room-list-empty {
	height: calc(100vh - 290px);
}

.dashboard-empty > img {
	width: 432px;
	height: 250px;
	margin-bottom: 12px;
}

.line-right:first-child::after {
	content: '';
	width: 1px;
	height: 16px;
	background: #e6e6e6;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/*tabs样式*/
.ant-tabs-bar {
	margin-bottom: 25px;
}

.tips {
	width: 316px;
	height: 52px;
	display: flex;
	align-items: center;
	position: absolute;
	left: 16px;
	bottom: 0;
	font-size: 14px;
	color: #e43937;
	line-height: 18px;
}

.time-list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 10px;
}

.time-item {
	height: 40px;
	width: 76px;
	background: rgba(0, 0, 0, 0.02);
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 2px;
	font-weight: 400;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 40px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.time-item-active {
	background: #fff4f4;
	border: 1px solid #e63e30;
	color: #e63e30;
}

.change-room-cate {
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	color: #666666;
	text-align: center;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	user-select: none;
	margin-bottom: 12px;
}

.change-room-cate:hover,
.change-room-cate-active {
	background: rgba(255, 255, 255, 0.02);
	border: 1px solid #e63e30;
	color: #e63e30;
}

.room-remark {
	width: 50%;
	margin-left: 8px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.qr-code {
	width: 260px;
	background: #ffffff;
	border: 1px solid #ececec;
	border-radius: 2px;
	position: relative;
	box-sizing: border-box;
	padding: 10px;

	img {
		width: 100%;
		height: 100%;
	}

	.qr-code-mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.pay-tips {
	margin-top: 6px;
	width: 260px;
	height: 50px;
	font-size: 12px;
	color: #e63e30;
	line-height: 18px;
	background: #fff4f3;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 15px;
}

.status_box {
	display: flex;
	align-items: center;

	.status {
		display: flex;
		align-items: center;
		margin-right: 12px;
	}

	.text {
		font-weight: 400;
		font-size: 16px;
		color: #666666;
	}

	.circle {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		display: block;
		margin-right: 8px;
	}

	.circle_1 {
		background: #f9f9f9;
		border: 1px solid #d8d8d8;
	}

	.circle_2 {
		background-image: linear-gradient(90deg, #e63e30 0%, #fc776c 99%);
		border: 1px solid #ffbeb7;
	}

	.circle_3 {
		background-image: linear-gradient(90deg, #08aee1 0%, #1edbe9 98%);
		border: 1px solid #b5eaee;
	}

	.circle_4 {
		background-image: linear-gradient(90deg, #fb7542 0%, #fea871 96%);
		border: 1px solid #ffceb6;
	}

	.circle_5 {
		background-image: linear-gradient(90deg, #2ec282 0%, #63e2aa 99%);
		border: 1px solid #c5ebda;
	}
}

.customer-tag {
	font-weight: bold;
	font-size: 10px;
	line-height: 1;
	border-radius: 3px;
	padding: 5px;
	color: #ffffff;
	margin-left: 4px;
}

.customer-tag.tag-1 {
	background: #6c593d;
}

.customer-tag.tag-2 {
	background: #896a3c;
}

.customer-tag.tag-3 {
	background: #af8c57;
}

.customer-tag.tag-4 {
	background: #d6ab6b;
}

.name-wrapper {
	flex-wrap: wrap;
	.ant-btn {
		margin-bottom: 8px;
	}
}

.search-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 20px;

	.search-title {
		position: relative;
		font-size: 18px;
		font-weight: bold;
		color: #333;
	}

	.search-title::before {
		position: absolute;
		left: -68px;
		top: 14px;
		content: '';
		width: 60px;
		height: 1px;
		background-image: linear-gradient(270deg, #e6e6e6 0%, #ffffff 99%);
		margin-right: 8px;
	}

	.search-title::after {
		position: absolute;
		right: -68px;
		top: 14px;
		content: '';
		width: 60px;
		height: 1px;
		background-image: linear-gradient(90deg, #e6e6e6 1%, #ffffff 100%);
	}

	.search-tips {
		padding: 5px 6px;
		margin: 6px 0 13px 0;
		background: #fff6ef;
		border-radius: 18px;
		font-size: 12px;
		color: #f8a23e;
	}

	.search-box {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 240px;

		.search-box-list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 100%;
			min-height: 60px;

			.search-item {
				width: 40px !important;
				height: 40px !important;
				text-align: center;
				border: 1px solid #999;
				border-radius: 8px;
				font-size: 24px;
				color: #333;

				.search-item_focus {
					height: 100%;
					width: 100%;
					-webkit-animation-name: opacity;
					-webkit-animation-timing-function: ease-in-out;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 1s;
					box-sizing: border-box;
				}

				@keyframes opacity {
					0% {
						opacity: 1;
					}

					100% {
						opacity: 0;
					}
				}
			}
		}
	}
}
</style>
